import { GatsbyBrowser, Script } from 'gatsby';
import * as React from 'react';

import Footer from './Footer';
import Header from './Header';

export const Layout: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return (
    <>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-5VC3V7V"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      <Header />
      <main className="min-h-screen">{element}</main>
      <Footer />
      <Script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5VC3V7V');`}
      </Script>
    </>
  );
};

export default Layout;
